<template lang="html">
  <v-row>
    <v-col cols="12">
      <v-model-table model-name="MasterGroups" :model-api="['models','Master','MasterGroups']" model-key="groupKey" :headers="headers" searchable expandable importable serverPagination>
        <template v-slot:modelForm="{data,rules,isCreating}">
          <v-container pa-0 fluid>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field :rules="[rules.require('Group key')]" v-model="data.groupKey" label="Group Key" :disabled="!isCreating"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="data.groupName" label="Group Name"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="data.description" label="Description"></v-text-field>
                <v-textarea v-model="data.propertiesTemplate" label="Properties template" auto-grow></v-textarea>
              </v-col>
              <v-col cols="12" md="12" v-if="data.propertiesTemplate">
                <v-container fluid ma-0 pa-0>
                  <v-row>
                    <v-col cols="12" md="8">
                      <span class="title font-weight-medium">Default properties</span>
                      <v-form-pad v-model="data.defaultProperties" :template="data.propertiesTemplate"></v-form-pad>
                    </v-col>
                    <v-col md="4" v-if="data.defaultProperties">
                      <v-card>
                        <v-card-title class="subheading pa-2">
                          Raw value
                        </v-card-title>
                        <v-card-text class="pa-2">
                          <v-json-pretty :data="data.defaultProperties"></v-json-pretty>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn flat @click="data.defaultProperties={}">Clear</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:item.action="props">
          <v-icon class="mx-1" @click.stop="props.actions.editData(props.item)">$vuetify.icons.edit</v-icon>
          <v-icon class="mx-1" color="red darken-3" @click.stop="props.actions.deleteData(props.item)">$vuetify.icons.delete</v-icon>
          <v-icon class="mx-1" @click.stop="viewItems(props.item)">assignment</v-icon>
        </template>
        <template v-slot:expanded-item="props">
          <td :colspan="props.headers.length">
            <v-container>
              <v-row dense>
                <v-col cols="4" md="2">
                  <v-card-text>Properties Template</v-card-text>
                </v-col>
                <v-col cols="8" md="10" pa-2>
                  <template v-if="props.item.propertiesTemplate">
                    <v-form-pad :template="props.item.propertiesTemplate"></v-form-pad>
                  </template>
                  <template v-else>
                    <span class="">No properties</span>
                  </template>
                </v-col>
                <v-col cols="4" md="2">
                  <v-card-text>Default Properties</v-card-text>
                </v-col>
                <v-col cols="8" md="10" pa-2>
                  <v-json-pretty :data="props.item.defaultProperties"></v-json-pretty>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
      </v-model-table>
    </v-col>
  </v-row>
</template>

<script>
import vJsonPretty from 'vue-json-pretty'

export default {
  data: ()=>({
    headers: [
      {
        text: 'Key',
        value: 'groupKey',
        class: 'body-2'
      },
      {
        text: 'Name',
        value: 'groupName',
        class: 'body-2'
      },
      {
        text: 'Description',
        value: 'description',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  components: {
    vJsonPretty,
  },
  methods: {
    viewItems(MasterGroup) {
      this.$emit('viewItems',MasterGroup)
    },
  },
}
</script>

<style lang="css">
</style>
