<template lang="html">
  <v-row>
    <v-col cols="12" py-2>
      <span class="display-1">Master of everything</span>
    </v-col>
    <v-col cols="12" py-2>
      <keep-alive>
        <template v-if="MasterGroup==null">
          <MasterGroups class="py-2" @viewItems="viewItems"></MasterGroups>
        </template>
      </keep-alive>
        <template v-if="MasterGroup!=null">
          <v-btn text @click="closeItems"><v-icon>$vuetify.icons.prev</v-icon>Back to Master Groups</v-btn>
          <MasterItems class="py-2" :MasterGroup="MasterGroup"></MasterItems>
        </template>
    </v-col>
  </v-row>
</template>

<script>
import MasterGroups from './MasterGroups'
import MasterItems from './MasterItems'

export default {
  data: ()=>({
    MasterGroup: null
  }),
  components: {
    MasterGroups,
    MasterItems
  },
  methods: {
    viewItems: function(MasterGroup) {
      this.MasterGroup = MasterGroup
    },
    closeItems: function() {
      this.MasterGroup = null
    }
  }
}
</script>

<style lang="css">
</style>
