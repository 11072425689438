<template lang="html">
  <v-row>
    <v-col cols="12">
      <v-model-table model-name="MasterItems" :model-title="MasterGroup.groupName"
        :model-api="['models','Master','MasterItems']" :model-api-search="{groupKey: MasterGroup.groupKey}"
        :headers="headers" :initial-data="initialData" searchable importable exportable>
        <template v-slot:modelForm="{data,rules,isCreating}">
          <v-row>
            <v-col cols="6" pa-2>
              <v-text-field :rules="[rules.require('Item code')]"  v-model="data.itemCode" label="ItemCode"></v-text-field>
              <v-text-field v-model="data.itemValue" label="ItemValue"></v-text-field>
              <v-text-field v-model="data.itemValueEN" label="ItemValueEN"></v-text-field>
              <v-text-field v-model="data.filterText" label="filterText"></v-text-field>
            </v-col>
            <v-col cols="6" pa-2>
              <span class="title font-weight-medium">Properties</span>
              <v-form-pad v-model="data.properties" :template="MasterGroup.propertiesTemplate"></v-form-pad>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.action="props">
          <v-icon class="mx-1" @click.stop="props.actions.editData(props.item)">$vuetify.icons.edit</v-icon>
          <v-icon class="mx-1" color="red darken-3" @click.stop="props.actions.deleteData(props.item)">$vuetify.icons.delete</v-icon>
        </template>
      </v-model-table>
    </v-col>
  </v-row>
</template>

<script>
import {assign} from 'lodash'

export default {
  data: ()=>({
    headers: [
      {
        text: 'Code',
        value: 'itemCode',
        class: 'body-2'
      },
      {
        text: 'Value (Default)',
        value: 'itemValue',
        class: 'body-2'
      },
      {
        text: 'Value (Eng)',
        value: 'itemValueEN',
        class: 'body-2'
      },
      {
        text: 'filterText',
        value: 'filterText',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  computed: {
    initialData() {
      let returnData = {groupKey: this.MasterGroup.groupKey}
      if (this.MasterGroup.defaultProperties !== null) returnData = assign(returnData,{properties: this.MasterGroup.defaultProperties})
      else returnData = assign(returnData,{properties: {}})
      return returnData
    }
  },
  props: {
    MasterGroup: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="css">
</style>
